<template>
  <div class="search" ref="search">
    <div class="c">
      <a-input>
        <template #prefix>
          <icon-search />
        </template>
        <template #suffix>
          <icon-close @click="close" />
        </template>
      </a-input>
      <div class="list">
        <div class="item">文本文本文本</div>
        <div class="item">文本文本文本</div>
        <div class="item">文本文本文本</div>
        <div class="item">文本文本</div>
      </div>
    </div>
  </div>
  <div class="nav">
    <div class="user">
      <!-- <icon-search class="icon-search" @click="open" /> -->
      <!-- <a-dropdown>
        <a-button class="c-btn"><icon-qq class="c" /><icon-down /></a-button>
        <template #content>
          <a-doption class="c-option">唤醒小C</a-doption>
          <a-doption class="c-option">小C主页</a-doption>
        </template>
      </a-dropdown> -->
      <img width="128" height="16" class="img" src="@/assets/cybereng.png" />
      <span class="dividing">|</span>
      <span class="name">{{ user.userFullname }}</span>
    </div>
  </div>
  <nav class="menu">
    <router-link
      to="/brand-display"
      class="item"
      :class="{ active: $route.path.includes('/brand-display') }"
    >
      <div>关于我们</div>
      <div class="en">ABOUT US</div>
      <div class="border">
        <div class="transition"></div>
      </div>
    </router-link>
    <!-- 产品集市临时权限代码，后续删除 -->
<!--    <div-->
<!--      v-if="!isProductUser"-->
<!--      class="item"-->
<!--      :class="{ active: $route.path.includes('/productTemp') }"-->
<!--      @mouseenter="mouseenter($event, 'product')"-->
<!--      @mouseleave="mouseleave($event, 'product')"-->
<!--    >-->
<!--      <div>产品集市</div>-->
<!--      <div class="en">PRODUCT BAZAAR</div>-->
<!--      <div class="border">-->
<!--        <div class="transition"></div>-->
<!--      </div>-->
<!--      <SubProductMenu ref="productmenu"></SubProductMenu>-->
<!--    </div>-->
    <router-link
      to="/product-list"
      class="item"
      :class="{ active: $route.path.includes('/product-list') }"
    >
      <div>产品研发</div>
      <div class="en">PRODUCT RESEARCH</div>
      <div class="border">
        <div class="transition"></div>
      </div>
    </router-link>
    <router-link to="/" class="icon"
      ><img width="180" height="32" alt="logo" src="@/assets/logo.png"
    /></router-link>
    <router-link
      to="/activity"
      class="item"
      :class="{ active: $route.path.includes('/activity') }"
    >
      <div>活动资讯</div>
      <div class="en">ACTIVITY INFORMATION</div>
      <div class="border">
        <div class="transition"></div>
      </div>
    </router-link>
    <router-link
      to="/ecological-cooperation/joint-ventures"
      class="item"
      :class="{
        active: $route.path.includes('/ecological-cooperation/joint-ventures'),
      }"
    >
      <div>生态合作</div>
      <div class="en">ECOLOGICAL COOPERATION</div>
      <div class="border">
        <div class="transition"></div>
      </div>
    </router-link>
  </nav>
</template>

<script setup>
import { ref } from "vue";
import { getUser } from "@/utils/auth";
import SubProductMenu from "./SubProductMenu.vue";

const user = getUser();

const search = ref(null);
const open = () => {
  search.value.style.display = "block";
};
const close = () => {
  search.value.style.display = "none";
};
// 产品集市临时权限代码，后续删除
const productmenu = ref(null);
const mouseenter = (e, name) => {
  switch (name) {
    case "product":
      productmenu.value.$el.style.display = "block";
      break;
  }
};
const mouseleave = (e, name) => {
  switch (name) {
    case "product":
      productmenu.value.$el.style.display = "none";
      break;
  }
};

// 特殊处理产品集市权限数据，后续删除
// import { getRoleUser, getRole } from "./api";
// const isProductUser = ref(false);
// getRole({
//   code: "productUser",
// })
//   .then((res) => {
//     if (res.status) {
//       getRoleUser({
//         pageNo: 1,
//         pageSize: 100,
//         roleId: res.data.id,
//       })
//         .then((res) => {
//           if (res.status) {
//             isProductUser.value = res.data.list.some(
//               (i) => i.userName === user.userName
//             );
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   })
//   .catch((err) => {
//     console.log(err);
//   });
</script>

<style lang="scss" scoped>
.search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  height: 110px;
  padding-top: 26px;
  background: linear-gradient(137deg, #00ffa0 0%, #00d2ff 25%, #0064ff 100%);
  .c {
    width: 1200px;
    margin: 0 auto;
  }
  .arco-input-wrapper {
    background: transparent;
    border-bottom: 1px solid #fff;
    color: #fff;
    padding: 0;
    &.arco-input-focus {
      border-color: transparent;
      border-bottom-color: #fff;
      box-shadow: none;
    }
    .arco-input-prefix > svg,
    .arco-input-suffix > svg {
      font-size: 28px;
      color: #fff;
    }
    .arco-input-suffix > svg {
      cursor: pointer;
    }
    :deep(.arco-input.arco-input-size-medium) {
      padding-bottom: 7px;
      font-size: 23px;
    }
  }
  .list {
    display: flex;
    margin-top: 15px;
    .item {
      margin-right: 32px;
      color: #fff;
    }
  }
}
.nav {
  background: #f5f6fa;
  .user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 1200px;
    height: 40px;
    margin: 0 auto;
    background: #f5f6fa;
    color: #555;
  }
  .icon-search {
    width: 18px;
    height: 18px;
    cursor: pointer;
    &:hover {
      color: #0064ff;
    }
  }
  .c-btn {
    background-color: #f5f6fa;
  }
  .c-btn.arco-dropdown-open,
  .c-btn:hover {
    background-color: #f5f6fa;
    color: #0064ff;
  }
  .c {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
  .arco-dropdown-open {
    .arco-icon-down {
      transform: rotate(180deg);
    }
  }
  .img {
    position: relative;
    top: 2px;
    margin-right: 16px;
  }
  .dividing {
    position: relative;
    top: -1px;
    margin-right: 16px;
    color: #ccc;
  }
  .name {
    margin-right: 16px;
  }
}
.arco-dropdown-option:not(.arco-dropdown-option-disabled).c-option {
  padding: 0 28px;
  &:hover {
    background: #fff;
    color: #0064ff;
  }
}
.menu {
  display: flex;
  justify-content: center;
  width: 1200px;
  margin: 0 auto;
  .item {
    position: relative;
    width: 160px;
    height: 60px;
    padding-top: 20px;
    margin-right: 72px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    user-select: none;
    color: #191919;
    &:hover {
      .border .transition {
        width: 160px;
      }
    }
    &:active,
    &.active {
      color: #0064ff;
      .en {
        color: #0064ff;
      }
      .border {
        opacity: 1;
        .transition {
          width: 160px;
        }
      }
    }
    &:nth-child(2),
    &:nth-child(5) {
      margin-right: 0;
    }
    .en {
      width: 320px;
      margin-top: 5px;
      font-size: 20px;
      color: #cacdd2;
      transform: scale(0.5);
      transform-origin: left;
    }
    .border {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 160px;
      opacity: 0.6;
      .transition {
        width: 0;
        height: 4px;
        background: linear-gradient(
          137deg,
          #00ffa0 0%,
          #00d2ff 25%,
          #0064ff 100%
        );
        transition: width 0.3s ease-out;
      }
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 80px;
    margin: 0 108px;
  }
}
</style>
